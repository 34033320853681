import React from 'react';
import './index.scss';
import { Icon } from 'antd';
import logo from '../../../assets/images/logos/logo_dark.svg';

const FormContainer = (props) => {
    return (
        <div className="form-container">
            <img src={logo} className="logo" alt="logo" />
            <p className="tagline">Convert Visitors Into Customers</p>

            <div className="content">
                {props.children}
            </div>

            <div className="footer">
                <div className="links">
                    <a href="https://letx.co/legals/terms" rel="noopener noreferrer" target="_blank">Terms</a>
                    <a href="https://letx.co/legals/privacy" rel="noopener noreferrer" target="_blank">Privacy</a>
                    <a href="https://help.letconvert.com" rel="noopener noreferrer" target="_blank">Help</a>
                </div>

                <div className="copyright-text">
                    <span>copyright <Icon type="copyright" /> 2019 LetConvert</span>
                </div>
            </div>
        </div>
    );
};

export default FormContainer;
import React from 'react';
import logo from '../../../../assets/images/logos/logo_dark.svg';
import './index.scss';

const Footer = (props) => {
  return (
    <footer className="letconvert-footer">
      <ul>
        <li><img src={logo} alt="letconvert footer logo" /></li>
        {/* <li><a href="#/">Getting Started</a></li> */}
        <li><a href="https://help.letconvert.com">Support</a></li>
        <li><a href="https://letx.co/terms">Terms of Service</a></li>
        <li><a href="https://letx.co/legals/privacy">Privacy Policy</a></li>
      </ul>
    </footer>
  );
}

export default Footer;
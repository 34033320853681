import React, { Component } from 'react';
import './index.scss';
import { Card, Table, Button, Icon, Tooltip, Row, Col, message, Modal } from 'antd';
import AddReviewModal from '../AddReviewModal';
import MainHeadingBar from '../../Partials/MainHeadingBar';
import { ReactComponent as EditReviewIcon } from '../../../../assets/images/icons/editReview.svg'
import { ReactComponent as DeleteReviewIcon } from '../../../../assets/images/icons/delete.svg'
import { getReviews, deleteReviews } from '../../../../helpers/ajax/reviewsAjax';
import NoData from '../../Partials/NoData';
import { CDN_URL } from '../../../../config';
import SearchField from '../../Partials/SearchField';

class UserReviews extends Component {
    state = {
        selectedRowKeys: [],
        reviews: [],
        pools: [],
        loading: true,
        error: false,
        errorMsg: '',
        filters: {
            search: '',
            pool: '',
        },
        paging: {
            pageSize: 10,
            total: 0,
            page: 1
        },
        addReviewModal: {
            visible: false,
            edit: false,
            review: {}
        }
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    fetchReviews() {
        getReviews(
            this.props.match.params.domid,
            this.state.paging.pageSize,
            (this.state.paging.page - 1) * this.state.paging.pageSize,
            "_id",
            "-1",
            this.state.filters.search,
            this.state.filters.pool
        ).then(res => this.setState(res));
    }

    componentDidMount() {
        if (this.props.match.params.pool) {
            this.setState({
                selectedRowKeys: [],
                filters: {
                    ...this.state.filters,
                    pool: this.props.match.params.pool
                }
            }, () => this.fetchReviews())
        } else {
            this.fetchReviews();
        }
    }

    setFilter(key, value) {
        this.setState({
            filters: {
                ...this.state.filters,
                [key]: value
            },
            loading: true,
            paging: {
                pageSize: 10,
                total: 0,
                page: 1
            }
        }, () => this.fetchReviews())
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;

        this.setState({
            reviews: [],
            loading: true,
            paging: {
                ...this.state.pagination,
                page: pager.current
            }
        }, () => this.fetchReviews());
    };

    deleteSelectedReviews(ids) {
        deleteReviews(this.props.match.params.domid, ids)
            .then((res) => {
                if (res.data.success === 1) {
                    this.setState({
                        reviews: this.state.reviews.filter((r) => ids.indexOf(r._id["$id"]) === -1)
                    })
                    message.success("Review(s) deleted successfully!");
                } else {
                    message.error(res.data.msg);
                }
            }).catch(e => {
                message.error(e.message);
            })
    }

    confirmDelete(ids) {
        const that = this;
        Modal.confirm({
            title: 'Are you sure',
            content: 'Are you sure you want to delete the review(s). Once deleted, reviews cannot be recovered',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                that.deleteSelectedReviews(ids);
            }
        });
    }

    toggleAddReviewModal() {
        this.setState({
            addReviewModal: {
                ...this.state.addReviewModal,
                visible: !this.state.addReviewModal.visible,
            }
        }, () => {
            if (!this.state.addReviewModal.visible) {
                this.setState({
                    addReviewModal: {
                        ...this.state.addReviewModal,
                        edit: undefined,
                        review: {}
                    }
                })
            }
        })
    }

    toggleEditReviewModal(review) {
        this.setState({
            addReviewModal: {
                ...this.state.addReviewModal,
                visible: !this.state.addReviewModal.visible,
                edit: true,
                review
            }
        })
    }

    onAddReview() {
        this.setState({
            selectedRowKeys: [],
            loading: true,
            paging: {
                pageSize: 10,
                total: 0,
                page: 1
            },
            addReviewModal: {
                visible: false,
                edit: false,
                review: {}
            }
        }, () => this.fetchReviews())
    }

    render() {
        const { selectedRowKeys, filters } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [{
            title: 'Review Summary',
            dataIndex: null,
            width: '300px',
            render: (_, record) => {
                return (
                    <div className="review-preview">
                        <div className="review-previewimage" style={{
                            background: `url(${CDN_URL}/reviews/${record.review_image})`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: 'no-repeat'
                        }}></div>
                        <div className="review-content">
                            <p className="reviewer_name">{record.review_name}</p>
                            <p className="review_text">
                                <Tooltip placement="bottomLeft" title={record.review_reviewtext}>
                                    {record.review_reviewtext}
                                </Tooltip>
                            </p>
                        </div>
                    </div>
                )
            }
        }, {
            title: 'Ratings',
            dataIndex: null,
            width: '200px',
            render: (_, record) => {
                const stars = isNaN(parseInt(record.review_stars)) ? 0 : parseInt(record.review_stars);
                return <p>{Array(stars).fill(0).map((_, i) => <Icon key={i} type="star" theme="filled" style={{ color: "#FFBA1F" }} />)}</p>
            }
        }, {
            title: 'Pools',
            dataIndex: 'review_pools',
            width: '200px',
            render: pools => pools && pools.map((p, i) => {
                return <span key={p}><Tooltip placement="bottomLeft" title={p}>
                    {p.length > 10 ? p.substring(0, 10) + '...' : p}
                </Tooltip>{i < (pools.length - 1) ? ', ' : ''}</span>
            })
        }, {
            title: 'Review Type',
            dataIndex: 'review_sourceid',
            width: '150px',
            render: source => source ? 'Imported' : 'Custom'
        }, {
            title: 'Actions',
            dataIndex: 'id',
            width: '200px',
            render: (_, record) => <div className="actions-div">
                <Tooltip placement="top" title="Edit"><Button type="default" className="edit-btn" onClick={() => this.toggleEditReviewModal(record)}><Icon component={EditReviewIcon} /></Button></Tooltip>
                <Tooltip placement="top" title="Delete"><Button type="danger" className="delete-btn" onClick={() => this.confirmDelete([record._id["$id"]])}><Icon component={DeleteReviewIcon} /></Button></Tooltip>
            </div>
        }];

        return (
            <div className="user-reviews">
                <MainHeadingBar title="User Reviews" helpText="Here you can manage the reviews." pageCTA={
                    <span><a href="#/" className="ant-btn btn-default" onClick={() => this.toggleAddReviewModal()}><Icon type="plus" />Add New Review</a></span>
                } {...this.props} />
                <Card style={{ padding: "30px" }}>
                    <Row style={{ marginBottom: "2.85rem" }}>
                        <Col xs={12}>
                            {this.state.filters.search && <span>Showing results for {this.state.filters.search}</span>}
                            {this.state.filters.pool && <span>Showing results for Pool: {this.state.filters.pool}</span>}
                            {this.state.selectedRowKeys.length > 0 &&
                                <Button
                                    style={{ background: "#F84B3D", color: "white" }}
                                    onClick={() => this.confirmDelete(this.state.selectedRowKeys)}>Delete Selected</Button>}
                        </Col>
                        <Col xs={12} style={{ textAlign: "right" }}>
                            <span>Search</span>
                            <SearchField
                                placeholder="Search for review, name and pool"
                                value={this.state.filters.search}
                                onChange={val => this.setState({ filters: { ...this.state.filters, search: val } })}
                                onSearch={value => this.setFilter('search', value)}
                                style={{ width: "20rem", marginLeft: "15px" }}
                            />
                        </Col>
                    </Row>
                    <Table
                        rowKey={record => record._id["$id"]}
                        dataSource={this.state.reviews}
                        columns={columns}
                        pagination={{ total: this.state.total }}
                        rowSelection={rowSelection}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        locale={
                            filters.search.length
                                ? {
                                    emptyText: <NoData
                                        heading="No Reviews Found!"
                                        content=""
                                    />
                                }
                                : {
                                    emptyText: <NoData
                                        heading="No Reviews Found!"
                                    />
                                }
                        }
                    />
                    <AddReviewModal
                        {...this.props}
                        visible={this.state.addReviewModal.visible}
                        edit={this.state.addReviewModal.edit}
                        review={this.state.addReviewModal.review}
                        pools={this.state.pools}
                        onAddReview={() => this.onAddReview()}
                        toggle={() => this.toggleAddReviewModal()} />
                </Card>
            </div>
        );
    }
}

export default UserReviews;
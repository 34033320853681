import React, { Component } from "react";
import { Modal, Input, Button, message, Select, Tooltip, Icon } from "antd";
import { addGoogleReviewSource } from "../../../../../../helpers/ajax/reviewsAjax";
import "./index.scss";
const { Option } = Select;

class GoogleReviewModal extends Component {
  state = {
    source: {
      id: undefined,
      source_id: "",
      source_name: "",
      source_minrating: 0
    },
    edit: false,
    errors: {}
  };

  resetModal(source = {}) {
    this.setState({
      source: {
        id: source._id ? source._id["$id"] : undefined,
        source_name: source.source_name ? source.source_name : "",
        source_id: source.source_id ? source.source_id : "",
        source_minrating: source.source_minrating ? source.source_minrating : 0
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    this.resetModal(nextProps.source);
  }

  setField(field, value) {
    this.setState({
      source: {
        ...this.state.source,
        [field]: value
      }
    });
  }

  saveSource() {
    const source = this.state.source;
    let errors = {};
    if (source.source_name.trim().length === 0) {
      errors["review_name"] = "This is a required field";
    }
    if (source.source_id.trim().length === 0) {
      errors["review_id"] = "This is a required field";
    }
    if (source.source_minrating.trim().length === 0) {
      errors["review_minrating"] = "This is a required field";
    }
    if (Object.keys(errors).length) {
      this.setState({
        errors
      });
    } else {
      this.setState(
        {
          errors: {}
        },
        () => {
          addGoogleReviewSource(this.props.match.params.domid, {
            ...source,
            source_type: "google"
          })
            .then(res => {
              if (res.data.success === 1) {
                message.success("Source saved successfully!");
                if (this.props.onAddSource) {
                  this.props.onAddSource(res.data.source);
                }
              } else {
                message.error(res.data.msg);
              }
            })
            .catch(e => {
              message.error(e.message);
            });
        }
      );
    }
  }

  render() {
    return (
      <Modal
        className="google-review-modal"
        visible={this.props.visible}
        onCancel={() => this.props.toggleModal()}
        title="New Google Business"
        footer={null}
      >
        <div className="add-source-form">
          <div
            className={`ant-form-item-control ${
              this.state.errors.source_name ? "has-error" : ""
            }`}
          >
            <label>
              Give This Source A Name{" "}
              <Tooltip title="This is the name of your source. It is used for your internal reference only.">
                <Icon type="info-circle" theme="filled" />
              </Tooltip>
            </label>
            <Input
              placeholder="Enter A Source Name"
              value={this.state.source.source_name}
              onChange={e => this.setField("source_name", e.target.value)}
            />
            {this.state.errors.source_name && (
              <div className="ant-form-explain">
                {this.state.errors.source_name}
              </div>
            )}
          </div>
          <div
            className={`ant-form-item-control ${
              this.state.errors.source_id ? "has-error" : ""
            }`}
          >
            <label>
              Enter The Place ID{" "}
              <Tooltip
                title={
                  <span>
                    This is a unique id google has for your business. To know
                    how to find it, follow the guide{" "}
                    <a
                      href="https://help.letconvert.com/portal/kb/articles/how-can-i-find-my-google-business-account-id"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </span>
                }
              >
                <Icon type="info-circle" theme="filled" />
              </Tooltip>
            </label>
            <Input
              placeholder="Enter A Place ID"
              value={this.state.source.source_id}
              onChange={e => this.setField("source_id", e.target.value)}
            />
            {this.state.errors.source_id && (
              <div className="ant-form-explain">
                {this.state.errors.source_id}
              </div>
            )}
          </div>
          <div
            className={`ant-form-item-control ${
              this.state.errors.source_minrating ? "has-error" : ""
            }`}
          >
            <label>
              Minimum Rating{" "}
              <Tooltip title="This is the name of your source. It is used for your internal reference only.">
                <Icon type="info-circle" theme="filled" />
              </Tooltip>
            </label>
            <Select
              defaultValue="1"
              value={this.state.source.source_minrating}
              style={{ width: "100%" }}
              onChange={val => this.setField("source_minrating", val)}
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
            </Select>
            {this.state.errors.source_minrating && (
              <div className="ant-form-explain">
                {this.state.errors.source_minrating}
              </div>
            )}
          </div>
          <div className="ant-form-item-control">
            <Button
              className="submit-btn"
              size="large"
              onClick={() => this.saveSource()}
            >
              Save & Continue
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default GoogleReviewModal;
